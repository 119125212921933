<template>
    <div class="main-carrossel-dogs">
        <carousel :startPosition="3" :nav="true" :navText="['','']" :dots="false" :center="true" :loop="true" :responsive="{0:{items:2, startPosition:1},1000:{items:4},1600:{items:6}}">
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/AmericanWa.svg?raw" alt="Russian">
                <img class="item-img yes-animate" src="@/assets/images/dogs/American.svg?raw" alt="Russian">
                <p class="item-title">American</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/Space-suit2Wa.svg?raw" alt="Russian">
                <img class="item-img yes-animate" src="@/assets/images/dogs/Space-suit2.svg?raw" alt="Russian">

                <p class="item-title">Russian1</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img" src="@/assets/images/dogs/chinese.png" alt="Russian">
                <p class="item-title">Russian2</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/FrenchWa.svg?raw" alt="French">
                <img class="item-img yes-animate" src="@/assets/images/dogs/French.svg?raw" alt="French">
                <p class="item-title">French</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/GermanyWa.svg?raw" alt="Germany">
                <img class="item-img yes-animate" src="@/assets/images/dogs/Germany.svg?raw" alt="Germany">
                <p class="item-title">Germany</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img" src="@/assets/images/dogs/indian.png" alt="Russian">
                <p class="item-title">Russian3</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/Space-suitWa.svg?raw" alt="Russian">
                <img class="item-img yes-animate" src="@/assets/images/dogs/Space-suit.svg?raw" alt="Russian">
                <p class="item-title">Russian4</p>
            </div>
            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/RussiaWa.svg?raw" alt="Russian">
                <img class="item-img yes-animate" src="@/assets/images/dogs/Russia.svg?raw" alt="Russian">
                <p class="item-title">Russian5</p>
            </div>

            <div class="item">
                <div class="item-toltip">
                    <img src="@/assets/images/burst.png" >
                    <span>8 breeds available!</span>
                </div>
                <img class="item-img not-animate" src="@/assets/images/dogs/Rampur-hound-baseWa.svg?raw" alt="Russian">
                <img class="item-img yes-animate" src="@/assets/images/dogs/Rampur-hound-base.svg?raw" alt="Russian">
                <p class="item-title">Russian6</p>
            </div>
        </carousel>
    </div>
</template>

<script>

import carousel from 'vue-owl-carousel'
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: 'CarrosselDogs',
    components: { carousel }
}

</script>

<style lang="less">

@black: #2E3240;
@pink: #D551AD;
@bg: #ffe2d1;
@green: #97D26F;

.main-carrossel-dogs {
    padding: 0px 30px 40px;
    position: relative;
    &:before, &:after {
        content: '';
        width: 4px;
        height: 300px;
        background: #90c86a;
        position: absolute;
        top: 90px;
        left: 26px;
    }
    &:after {
        left: auto;
        right: 26px;
    }
    .owl-nav {
        margin: 0 auto;
        padding: 0;
        position: absolute;
        top: 255px;
        width: 32%;
        left: 1%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .owl-prev, .owl-next {
            font-size: 32px !important;
            color: @pink !important;
            background: transparent !important;
            margin: 0 !important;
            padding: 0 !important;
        }
        @media(min-width: 1600px) {
            width: 22%;
        }
        .owl-prev {
            background: url('~@/assets/images/arrow_left_color.svg?raw') center/cover no-repeat !important;
            width: 25px;
            height: 34px;
        }
        .owl-next {
            background: url('~@/assets/images/arrow_right_color.svg?raw') center/cover no-repeat !important;
            width: 25px;
            height: 34px;
        }
    }
    .owl-stage {
        display: flex;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
        min-height: 480px;
    }
    .owl-stage > .active {
        .item {
            align-items: flex-start;
        }
    }
    .owl-stage > .active ~ .active ~ .active {
        .item {
            align-items: flex-end;
        }
    }
    .owl-stage > .active ~ .active ~ .active ~ .active {
        .item {
            align-items: center;
        }
    }
    .anima-lottie {
        margin: 0 !important;
        display: none;
        @media(max-width:768px) {
            height: 150px !important;
        }
    }
    @media(min-width:1600px) {
        .owl-stage > .active ~ .active {
            .item {
                align-items: flex-start;
            }
        }
        .owl-stage > .active ~ .active ~ .active ~ .active {
            .item {
                align-items: flex-end;
            }
        }
        .owl-stage > .active ~ .active ~ .active ~ .active ~ .active ~ .active {
            .item {
                align-items: center;
            }
        }
    }
    .owl-item {
        .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: all ease-in-out 0.3s;
            transform: scale(1);
            .item-img {
                width: auto;
                max-height: 220px;
            }
            img{
                &.not-animate{
                    display: block;

                }
                &.yes-animate{
                    display: none;
                }
            }
        }
        .item-title {
            font-family: 'Filson Pro';
            font-size: 16px;
            line-height: 34px;
            color: #000;
            transition: all ease-in-out 0.3s;
            opacity: 0;
            margin-top: 10px;
        }
        .item-toltip {
            position: absolute;
            width: 100px;
            height: 100px;
            top: -10px;
            right: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 3;
            text-align: center;
            transition: all ease-in-out 0.3s;
            opacity: 0;
            img {
                position: absolute;
                z-index: 1;
            }
            span {
                font-family: 'Filson Pro';
                font-size: 12px;
                line-height: 18px;
                color: #fff;
                position: relative;
                z-index: 2;
            }
        }
        &.center {
            .item {
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center !important;
                aspect-ratio: 1;
                background: #fff;
                transform: scale(1.5) translateX(2%);
                @media(max-width:1200px) and (min-width:1075px){
                    transform: scale(1.4) translateX(23px);
                }
            }
            .item-img {
               // display: none;
            }
            .anima-lottie {
                display: block;
            }
     
            .item-title, .item-toltip {
                opacity: 1;
            } 
            img{
                &.not-animate{
                    display: none;
                }
                &.yes-animate{
                    display: block;
                }
            }
        }
    }
}


@media(max-width:768px) {
    .main-carrossel-dogs .owl-item .item {
         transform: scale(0.6);
    }
    .main-carrossel-dogs .owl-item.center .item {
        transform: scale(1.2);
    }
    .main-carrossel-dogs .owl-item.center .item .item-img {
        max-height: 150px;
    }
    .main-carrossel-dogs .owl-item .item-title {
        position: absolute;
        bottom: -50px;
        font-size: 18px;
        line-height: 60px;
    }
    .main-carrossel-dogs .owl-stage {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 330px;
    }
    .main-carrossel-dogs .owl-item .item-toltip {
        width: 80px;
        height: 80px;
        top: -38px;
        right: -27px;
    }
    .main-carrossel-dogs .owl-item .item-toltip span {
        font-size: 10px;
    }
    .main-carrossel-dogs .owl-nav {
        top: 146px;
        width: 56%;
    }
    .main-carrossel-dogs .owl-item .item-toltip {
        display: none;
    }
}

@media(min-width:500px) and (max-width:768px) {
    .main-carrossel-dogs .owl-stage {
        min-height: 570px;
    }
    .main-carrossel-dogs .owl-nav {
        top: 268px;
        width: 56%;
    }
    .main-carrossel-dogs .owl-item.center .item .item-img {
        max-height: 220px;
    }
    .main-carrossel-dogs .owl-item .item-toltip {
        width: 100px;
        height: 100px;
        top: -10px;
        right: -10px;
    }
    .main-carrossel-dogs .owl-item .item-toltip span {
        font-size: 12px;
    }
}

@media(min-width:769px) and (max-width:1200px) {
    .main-carrossel-dogs .owl-stage {
        min-height: 530px;
    }
    .main-carrossel-dogs .owl-nav {
        // top: 300px;
        // width: 65%;
        
    }
    .main-carrossel-dogs .owl-stage > .active .item {
        align-items: center;
    }
}

@media (min-width: 999px) and (max-width: 1200px){
    .main-carrossel-dogs .owl-nav {
        top: 360px;
        left: 5.5%;
        
    }
}

@media (min-width: 769px) and (max-width: 999px){
    .main-carrossel-dogs .owl-nav {
        top: 300px;
        width: 65%;
    }
}

@media(min-width:890px) and (max-width:1200px) {
    .main-carrossel-dogs .owl-stage {
        min-height: 650px;
    }
}

</style>