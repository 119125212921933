<template>
  <div class="cont relative overflow-hidden pb-32 z-30">
    <BaseContainer class="text-left md:text-center">
      <div class="how-we-help pt-20 pb-10">
        <img class="cloud-top" src="../../assets/images/cloud-left.png" />
        <h2 class="text-cdlpink">NFT COLLECTIONS</h2>
        <p class="mt-4 leading-relaxed">
          Our additional planned source of income for the Impact Fund
        </p>
        <img class="cloud-bottom" src="../../assets/images/cloud-right.png" />
      </div>

      <!-- boxes -->
      <div
        class="
          boxes
          grid
          md:grid-cols-2
          gap-4
          text-left
          md:flex-row md:flex-wrap md:justify-between
        "
      >
        <div class="box" v-for="item in items" :key="item.id">
          <img
            :src="item.icon"
            class="float-right -mt-12"
            :class="item.iconClass"
          />
          <div class="bg-cdlpeach-dark rounded-xl p-4 h-full">
            <h3 class="text-2xl pb-2 text-cdlpink">{{ item.title }}</h3>
            <p class="leading-relaxed">{{ item.text }}</p>
            <BaseButton class="mt-4" :page="item.toPage" v-if="item.toPage && item.cta" :ukraine="item.ukraine"
              >{{item.cta}}</BaseButton
            >
          </div>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import mixinInitLoader from "@/mixins/initLoader"

export default {
  name: "Collections",
  mixins: [mixinInitLoader],
  components: {},
  data() {
    return {
      items: [
        {
          id: "collection-ukraine",
          title: "Heroes of Ukraine",
          text: "our case study and testing ground for the upcoming NFT projects in the plan.",
          toPage: "/ukraine",
          cta: 'Get your Hero',
          ukraine: true
        },{
          id: "collection1",
          title: "Cuddle Doggos",
          text: "the first planned real NFT collection -  details to be announced.",
          toPage: "/nft/collection1",
          cta: 'Explore Doggos'
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../themes/variables.less";
@import "../../themes/variables.less";
.cont {
  .how-we-help {
    position: relative;
    .cloud-top {
      position: absolute;
      top: 30px;
      left: 90vw;
      width: 320px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }

    .cloud-bottom {
      position: absolute;
      width: 380px;
      z-index: 1;
      left: 90vw;
      bottom: 0px;
      animation: animate-cloud;
      animation-duration: 60s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: -1;
    }
  }

  @media (min-width: @bp-md) {
    .boxes {
      gap: 1rem;

      .box {
        flex-basis: 49%;
      }
    }
  }

  .animal-shelters {
    // .dog-1 {}
  }

  @keyframes animate-cloud {
    from {
      position: absolute;
      left: 95vw;
    }
    to {
      position: absolute;
      left: -90%;
    }
  }

  .boxes {
    .box {
      // background-color: theme('colors.cdlpeach.dark');
      // border-radius: 30px;
    }
  }
}
</style>
