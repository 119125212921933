<template>
  <div>
    <!-- <HeroCloud /> -->
    <!-- <ZorroDoggos /> -->
    <Collections class='pt-32' />
    <!-- <TheImpact/> -->
    <!-- <CarrosselRoad/> -->
    <!-- <TheTeam /> -->

    <!-- </BaseContainer> -->
    <Footer class="mt-20" />
  </div>
</template>

<script>
import HeroCloud from "@/components/NFT/HeroCloud"
import ZorroDoggos from "@/components/NFT/ZorroDoggos"
import Collections from "@/components/NFT/Collections"
import TheTeam from "@/components/NFT/TheTeam"

// import TheImpact from "@/components/NFT/TheImpact"
// import CarrosselRoad from "@/components/NFT/CarrosselRoad"

import Footer from "@/components/common/Footer"
export default {
  name: "NFTs",
  components: {
    HeroCloud,
    Collections,
    ZorroDoggos,
    TheTeam,

    // TheImpact,
    // CarrosselRoad,
    Footer,
  },
  data() {
    return {}
  },
}
</script>
