<template>
  <section class="hero-cloud">
    <BaseContainer class="base-container relative z-30 lg:pb-60 xl:pb-80">
      <HeroIntro :title="hero.title" :subtitle="hero.subtitle" class=' md:w-4/6' />
    </BaseContainer>
    <BaseContainer class="relative">
    <div class='zorro-wrap'>
      <img
        style="display: none"
        src="@/assets/images/puppet.svg?raw"
        alt="Puppet"
      />
      <lottie-animation
        class="zorro"
        path="./lottie/Zorro.json"
        :width="zorro.width"
        :height="zorro.height"
      />
    </div>
    </BaseContainer>
  </section>
</template>

<script>
import HeroIntro from "@/components/common/HeroIntro"
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

export default {
  name: "HeroCloud",
  components: {
    LottieAnimation,
    HeroIntro,
  },
  data() {
    return {
      hero: {
        title: "Real-world change through impact NFTs",
        subtitle: "Cuddle Dogs blend the fun of NFTs with saving REAL animals.",
      },
    }
  },
  created() {
    // this.setCountdown()
  },
  computed: {
    viewport() {
      return this.$store.state.client.viewport
    },
    zorro() {
      if (["desktop"].includes(this.viewport.size)) {
        return {
          width: 612,
          height: 683,
        }
      } else if (["tablet"].includes(this.viewport.size)) {
        return {
          width: 431,
          height: 481,
        }
      } else {
        return {
          width: 431,
          height: 481,
        }
      }
    },
  },
  methods: {
    setCountdown() {
      // Set the date we're counting down to
      var countDownDate = new Date("Jan 5, 2022 15:37:25").getTime()

      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Display the result in the element with id="demo"
        if (document.getElementById("countdown")) {
          document.getElementById("countdown").innerHTML =
            "<div>" +
            days +
            "<span>Days</span></div><div>" +
            hours +
            "<span>Hours</span></div><div>" +
            minutes +
            "<span>Minutes</span></div><div>" +
            seconds +
            "<span>Seconds</span></div>"
        }
        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x)
          document.getElementById("countdown").innerHTML = "EXPIRED"
        }
      }, 1000)
    },
  },
}
</script>

<style lang="less">
@import "../../themes/variables.less";
@black: #2e3240;
@pink: #d551ad;
@bg: #ffe2d1;
@green: #97d26f;

.base-container {
  // padding-bottom: 450px;

  @media (min-width: @bp-md) {
    padding-bottom: 200px;
  }

  @media (min-width: @bp-lg) {
    padding-bottom: 260px;
  }
}

.zorro-wrap {
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: 0 auto;
    // bottom: calc(-20px + 5vw);
    // width: 375px;
    width: 100%;
    height: auto;
    // outline: 1px #ff0000 solid;

    @media (min-width: @bp-md) {
      position: absolute;
      width: 50%;
      bottom: calc(-40px + 5vw);
      right: 0;
    }

    @media (min-width: @bp-lg) {
      position: absolute;
      width: 50%;
      bottom: calc(-40px + 5vw);
      right: 0;
    }

    .zorro {
      padding-top: 50px;
      position: relative;
      width: 100%;
      z-index: 10;
    }
  }

.hero-cloud {
  position: relative;
  // padding-top: 140px;
  background: @bg url("~@/assets/images/clouds.png") repeat 0 0;
  animation: 28s linear 0s normal none infinite animateClouds;
  overflow: hidden;
  margin-bottom: -1px;
  // .puppet-floot {
  //   position: relative;
  //   img,
  //   .lottie-puppet {
  //     position: absolute;
  //     left: -50px;
  //     bottom: 15px;
  //     max-width: 100%;
  //     height: auto !important;
  //     @media (max-width: 1500px) {
  //       bottom: 60px;
  //     }
  //   }
  // }
  .row {
    position: relative;
    z-index: 3;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 70px;
    background: url("~@/assets/images/grass-curve-top.svg?raw");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 50px;
    background-position-x: center;
    background-position-y: top;
    z-index: 2;
    @media (max-width: 1500px) {
      bottom: 70px;
      padding-top: 90px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: @green;
    width: 100%;
    padding-top: 80px;
    z-index: 1;
  }
  &__title {
    font-size: 65px;
    line-height: 75px;
    color: @pink;
    margin-bottom: 30px;
    width: 578px;
    max-width: 100%;
  }
  &__description {
    font-family: "Filson Pro";
    font-size: 30px;
    line-height: 50px;
    color: @black;
    margin-bottom: 30px;
  }
  .box-time-line {
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
    @media (max-width: 1500px) {
      margin-bottom: 20px;
    }
    &__title {
      background: @pink;
      color: #fff;
      // padding: 15px 30px;
      // font-size: 22px;
      // line-height: 28px;
    }
    &__content {
      // padding: 20px 30px;
      .time-progress-info {
        display: flex;
        p {
          flex: 0 0 33.3333%;
          max-width: 100%;
          text-align: center;
          font-family: "Filson Pro";
          font-size: 16px;
          line-height: 23px;
          color: #000;
        }
      }
      .time-progress {
        position: relative;
        overflow: hidden;
        margin-bottom: 15px;
        height: 45px;
        background: #c3e9ff url("~@/assets/images/lines_rule.svg?raw")
          center/cover no-repeat;
        border-radius: 8px;
        .time-progress-value {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          background: @black;
          &:before {
            content: "";
            width: 45px;
            height: 45px;
            background: @black;
            border-radius: 8px;
            position: absolute;
            top: 0;
            right: -22.5px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 68px;
    div {
      flex: 0 0 25%;
      max-width: 100%;
      text-align: center;
      font-size: 35px;
      line-height: 40px;
      color: @black;
      border-right: 1px solid @black;
      &:last-child {
        border: none;
      }
      span {
        display: block;
        margin-top: 5px;
        width: 100%;
        font-family: "Filson Pro";
        font-size: 16px;
        line-height: 23px;
        color: @black;
      }
    }
  }
}

@keyframes animateClouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1450px 0;
  }
}

@media (max-width: 767px) {
  // .hero-cloud {
  //   .puppet-floot img,
  //   // .puppet-floot .lottie-puppet {
  //   //   position: relative;
  //   //   left: 0;
  //   //   bottom: 0;
  //   //   top: -70px;
  //   //   max-width: 100%;
  //   //   height: auto !important;
  //   // }
  // }
}

@media (max-width: 768px) {
  .hero-cloud {
    // padding: 70px 15px 0;
    &__title {
      font-size: 42px;
      line-height: 52px;
    }
    &__description {
      font-size: 24px;
      line-height: 36px;
    }
    &:after {
      padding-top: 96px;
    }
    &:before {
      bottom: 25px;
      padding-top: 80px;
      background-size: 100%;
    }

    .box-time-line__title,
    .box-time-line__content {
      padding: 15px;
    }
    .box-time-line__content .time-progress {
      background-size: 100%;
    }
    .box-time-line.--count {
      padding-bottom: 60px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .hero-cloud {
    // padding: 140px 30px 0;
  }
}
</style>
